const pageView = (page, pageName, affiliateId) => {
  if (global.rudderanalytics) {
    global.rudderanalytics.page(
      page,
      pageName,
      {
        affiliateId
      }
    )
  } else {
    console.error('rudderstack is not found')
  }
}

const fathomEvent = (eventName) => {
  try {
    global.fathom.trackEvent(eventName)
  } catch (err) {
    console.error('failed to track event:', eventName)
  }
}

export {
  pageView,
  fathomEvent
}
