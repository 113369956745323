<template>
  <div class="flex flex-col items-center mt-5 mb-14">
    <img class="h-64 w-64" loading="lazy"
      src="https://res.cloudinary.com/theburningmonk/image/upload/v1696345110/testslsapps/happiness-guarantee-v2.png" />

    <h2 class="text-xl text-center mt-2 md:w-2/4">
      If this course doesn't meet your expectations, you can ask for a <strong>FULL REFUND</strong>
      within 30 days. We promise a hassle-free refund, no questions asked.
    </h2>
  </div>
</template>
