import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import * as rudderanalytics from 'rudder-sdk-js'

rudderanalytics.load(
  process.env.VUE_APP_WRITE_KEY,
  process.env.VUE_APP_DATA_PLANE_URL)

global.rudderanalytics = rudderanalytics

createApp(App).use(router).mount('#app')
