<template>
  <div class="bg-white w-auto max-w-xl h-fit items-center px-10 py-10 border rounded-lg">
    <div class="max-w-sm mx-auto mb-10 flex flex-col text-black text-lg">
      <div class="flex justify-between">
        <span>Subtotal</span>
        <span>{{ subtotal }} {{ currency }}</span>
      </div>
      <div class="flex justify-between">
        <span>Tax</span>
        <span>{{ tax }} {{ currency }}</span>
      </div>
      <div class="flex justify-between border-t-2 border-black">
        <span>Total</span>
        <span>{{ total }} {{ currency }}</span>
      </div>
    </div>

    <div class="checkout-container border-t-2 border-dashed pt-2 overflow-auto"></div>
  </div>
</template>

<script>
import { VendorId } from '../libs/constants'
import { getCookie } from '../libs/utils'

export default {
  props: ['productId'],
  data () {
    return {
      currency: '...checking',
      subtotal: undefined,
      tax: undefined,
      total: undefined
    }
  },
  async mounted () {
    // capture the vue object before the Paddle callback
    const self = this

    global.Paddle.Setup({
      vendor: VendorId,
      eventCallback: function (evt) {
        self.currency = evt.eventData.checkout.prices.customer.currency
        self.total = parseFloat(evt.eventData.checkout.prices.customer.total)
        self.tax = parseFloat(evt.eventData.checkout.prices.customer.total_tax)
        self.subtotal = (self.total - self.tax).toFixed(2)
      }
    })

    const passthrough = {
      affiliateId: getCookie('affiliateId')
    }
    global.Paddle.Checkout.open({
      method: 'inline',
      product: this.productId,
      allowQuantity: true,
      disableLogout: true,
      frameTarget: 'checkout-container', // The className of your checkout <div>
      frameInitialHeight: 416,
      frameStyle: 'width:495px; min-width:495px; background-color: transparent; border: none;',
      passthrough: JSON.stringify(passthrough)
    })
  }
}
</script>
