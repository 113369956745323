<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.link {
  font-weight: bold;
  text-decoration: underline;
}

@font-face {
  font-family: "Kalam";
  src: local("Kalam"),
   url(./fonts/Kalam-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Corinthia";
  src: local("Corinthia"),
   url(./fonts/Corinthia-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Impact";
  src: local("Impact"),
   url(./fonts/Impact.ttf) format("truetype");
}

.hero-title {
  font-family: 'Impact';
}

</style>
