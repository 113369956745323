const ProductIds = {
  AppSyncMasterclassBasic: 640971,
  AppSyncMasterclassPlus: 640970,
  AppSyncMasterclassPremium: 640957,
  Llama: 640973,
  StepFunctions: 640976,
  CompleteBundle: 640977,
  TestingServerlessArchsPro: 809107,
  TestingServerlessArchsBasic: 809109
}

const VendorId = 124878

export {
  ProductIds,
  VendorId
}
