<template>
  <div class="mt-24 bg-gray-700 text-white w-full max-w-full py-14">
    <h1 class="text-3xl font-extrabold tracking-tighter">
      Set yourself on a path to
      <span class="underline underline-offset-4 text-yellow-200">SUCCESS</span>
      with serverless
    </h1>
    <p class="max-w-4xl mt-2 text-lg mx-auto px-3 md:px-10">
      Having no tests or a poorly thought-out testing strategy is a common
      reason why serverless projects fail in the real world.
      Think of all the hours you'd spend on manual testing and fixing flaky tests,
      or the time it'd take to troubleshoot critical bugs that creep into
      production because of missing tests.
    </p>
    <p class="max-w-4xl mt-2 text-lg mx-auto px-3 md:px-10">
      Testing serverless architectures can be straightforward,
      <span class="font-bold">with the right strategy</span>.
    </p>
    <p class="max-w-4xl mt-8 text-lg mx-auto px-3 md:px-10">
      Save yourself years of trial-and-error.
      <span class="font-bold text-yellow-200">Learn what works in a matter of hours.</span>
    </p>
  </div>
</template>
