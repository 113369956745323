<template>
  <div class="flex flex-col">
    <img v-if="imageName" loading="lazy"
      class="h-24 w-44 lg:h-36 lg:w-auto border-2 rounded-md border-gray-600"
      :src="`https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_144/testslsapps/${imageName}`">
    <img v-if="!imageName" loading="lazy"
      class="h-24 w-44 lg:h-36 lg:w-auto border-2 rounded-md border-gray-600"
      src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_144/testslsapps/coming_soon.png">
    <p class="text-left text-base">{{ name }}</p>
  </div>
</template>

<script>
export default {
  props: ['name', 'imageName']
}
</script>
