import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ThankYouView from '../views/ThankYouView.vue'
import CheckOutBasicView from '../views/CheckOutBasicView.vue'
import CheckOutProView from '../views/CheckOutProView.vue'

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/thank-you', name: 'ThankYou', component: ThankYouView },
  { path: '/check-out-basic', name: 'CheckOutBasic', component: CheckOutBasicView },
  { path: '/check-out-pro', name: 'CheckOutPro', component: CheckOutProView },
  { path: '/:pathMatch(.*)', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

export default router
