<template>
  <div id="faq" class="mt-32">
    <div class="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-800 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-4xl font-extrabold">FAQs</h2>
      <div class="mt-6 pt-10 text-left">
        <FaqQuestion question="What if I don't like it?">
          <p class="mt-2 text-lg text-gray-600">
            No problems. If you don't like it, you can get a full refund within
            <span class="font-extrabold text-black">30 days</span> of your
            purchase with no questions asked.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Is there a way I can talk to someone if I get stuck?">
          <p class="mt-2 text-lg text-gray-600">
            You can reach out to me or your fellow students via the course forum.
            There are always people willing to help each other out, and I keep a
            close eye on the forum as well.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Will the course be updated as things changes?">
          <p class="mt-2 text-lg text-gray-600">
            Yes I plan on maintaining this course. It is not a hands-on course
            and generally sticks to evergreen approaches for testing serverless
            architectures. As new tools and techniques become available, they'll
            be updated here.
          </p>
        </FaqQuestion>

        <FaqQuestion question="What are the prerequisites for taking this course?">
          <p class="mt-2 text-lg text-gray-600">
            This course is laser focused on testing serverless architectures, so
            we won't be covering the basics of services such as Lambda, API Gateway,
            AppSync and Step Functions. So if you're thinking about how to test
            your serverless architecture, then I assume you're already working with
            these technologies or at least have a working understanding of them.
          </p>
        </FaqQuestion>

        <FaqQuestion question="How is this different from your other courses?">
          <p class="mt-2 text-lg text-gray-600">
            This is brand new content recorded exclusively for this course. But
            there are overlaps with some of my other courses/workshops. For
            example, the "Testing API Gateway APIs" and "Testing AppSync APIs"
            chapters use the same evergreen approaches that I have covered in
            <a class="link" target="_blank" href="https://productionreadyserverless.com/">
              Production-Ready Serverless</a> and
            <a class="link" target="_blank" href="https://appsyncmasterclass.com/">
              AppSync Masterclass</a>.
            Having said that, the rest of the course (e.g. testing Step Functions
            and Event-Driven Architectures) are brand new and have not been covered
            by any of my other contents.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Can I preview the content before I buy?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, you can. There is a free preview in each of the packages where
            you can access a number of lessons for free. Go to the bottom of the
            <a href="#pricing" class="link">pricing</a> section for the links to
            sign up for free preview.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Is there any kind of certificate of completion?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, there is! Once you complete the course, you will receive a
            <span class="font-extrabold text-black">certificate of completion</span>
            via email.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Is there an upgrade path if I change my mind later and want a higher package?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, there is! If you want to upgrade to a higher package (hopefully
            that means you see the value of this course!) then drop me an email at
            <a class="link" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=Upgrade%20Testing%20Serverless%20Architectures">
              theburningmonk@hey.com</a>
            with a copy of your original invoice and I'll sort it out for you.
          </p>
        </FaqQuestion>

        <FaqQuestion question="I can't afford your course, is there anything you can do to help?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, absolutely. Drop me an email at
            <a class="link" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=Help%20with%20purchasing%20Testing%20Serverless%20Architectures">
              theburningmonk@hey.com</a>
            and explain your situation to me. Maybe you're a student, or you're
            out-of-work, or maybe the price is just too high for your country,
            whatever the case, I would love to help you out.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Do you have an affiliate program?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, I do. You can become an affiliate by submitting a request to
            <a class="link underline" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=Affiliate%20Program">
              theburningmonk@hey.com
            </a> Affiliates earn <strong>50%</strong> of the revenue they generate.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Do you support Purchase Power Parity (PPP)?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, we support PPP for <span class="font-bold">161 countries</span>.
            You should see a banner at the top of the website with a discount code for
            your country.
            <span class="font-bold">
              Please make sure that you're not connected through a VPN.
            </span>
          </p>
        </FaqQuestion>

        <!-- <FaqQuestion question="Do you have a Privacy Policy?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, you can see our Privacy Policy
            <a class="link" target="_blank"
              href="https://app.termly.io/document/privacy-policy/db7bbcd1-d65f-419b-95cb-98e4664b36a6">
              here</a>.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Do you have a Terms and Conditions (T&C)?">
          <p class="mt-2 text-lg text-gray-600">
            Yes, you can see our Terms and Conditions
            <a class="link" target="_blank"
              href="https://app.termly.io/document/terms-of-use-for-website/e171430f-778f-47b4-8091-7fbd9d37b264">
              here</a>.
          </p>
        </FaqQuestion> -->
      </div>
    </div>
  </div>
</template>

<script>
import FaqQuestion from './FaqQuestion.vue'

export default {
  name: 'FaqComponent',
  components: {
    FaqQuestion
  }
}
</script>
