<template>
  <div class="w-full mx-auto">
    <button @click="toggle" class="text-lg sm:text-xl font-semibold w-full text-left flex justify-between items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none">
      <span>{{ question }}</span>
      <svg :class="{'transform rotate-180': isOpen}" class="fill-current h-8 w-8 transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M5.95 7.95l3.99 4.01 3.99-4.01-1.42-1.42-2.57 2.58-2.57-2.58-1.42 1.42z"/>
      </svg>
    </button>
    <div v-show="isOpen" class="pl-4 my-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['question'],
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
