<template>
  <div class="relative isolate bg-white pb-32 pt-24 sm:pt-32 text-left">
    <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">
      <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end" aria-hidden="true">
      <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What students are saying</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
        <figure class="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
          <blockquote class="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
            <p>{{ `“${featuredTestimonial.body}”` }}</p>
          </blockquote>
          <figcaption class="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
            <img class="h-14 w-14 flex-none rounded-full bg-gray-50" :src="featuredTestimonial.author.imageUrl" alt="" />
            <div class="flex-auto">
              <div class="font-semibold text-lg">{{ featuredTestimonial.author.name }}</div>
              <div class="text-gray-600 text-lg">{{ featuredTestimonial.author.handle }}</div>
            </div>
          </figcaption>
        </figure>
        <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
          <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
            <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-gray-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="h-10 w-10 rounded-full bg-gray-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold">{{ testimonial.author.name }}</div>
                  <div class="text-gray-600">{{ testimonial.author.handle }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const imageUrl = (filename, size = 80) => {
  return `https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_${size},w_${size}/testslsapps/${filename}`
}

const featuredTestimonial = {
  body: "Yan's knowledge of AWS serverless is second to none, and when you finish this course I guarantee you will come away with a ton of ideas for improving the test quality of your own serverless projects.",
  author: {
    name: 'Paul Swail',
    handle: 'Serverless Expert & AWS Consultant',
    imageUrl: imageUrl('paul_swail.png', 100)
  }
}
const testimonials = [
  [
    [
      {
        body: "I love this course! This is a great breakdown of the problems that people run into testing in the cloud. I've learned some really clear gaps in our testing strategy and lots of tools/patterns to solve them.",
        author: {
          name: 'Joshua Harris',
          handle: 'Senior Software Engineer',
          imageUrl: imageUrl('joshua-harris.jpg')
        }
      },
      {
        body: "Really useful, would highly recommend. Confirms what we're already doing right and introduces lots of great ideas based on practical experience.",
        author: {
          name: 'Harry Patrick',
          handle: 'Software Development Engineer In Test',
          imageUrl: imageUrl('harry-patrick.jpg')
        }
      }
    ],
    [
      {
        body: 'Best course about testing in serverless architectures.',
        author: {
          name: 'Ronnie Moncayo',
          handle: 'VP of Engineering',
          imageUrl: imageUrl('ronnie-moncayo.jpg')
        }
      }
    ]
  ],
  [
    [
      {
        body: 'Outstanding! Once again the content and advice is first class, pragmatic and clear.',
        author: {
          name: 'Gary Craine',
          handle: 'Senior Software Developer',
          imageUrl: imageUrl('gary-craine.jpeg')
        }
      }
    ],
    [
      {
        body: 'Super Helpful. Everything you need to know to establish best practices for testing AWS Serverless infrastructure! The course not only goes into how to write tests, but also how to visualize what needs to be tested depending on your infra.',
        author: {
          name: 'Anthony Williams',
          handle: 'Software Engineer',
          imageUrl: imageUrl('anthony-williams.jpg')
        }
      },
      {
        body: 'Comprehensive deep dive into serverless testing',
        author: {
          name: 'Roman Doric',
          handle: 'Senior Test Engineer at Sage',
          imageUrl: imageUrl('roman-doric.jpeg')
        }
      }
    ]
  ]
]
</script>
