<template>
  <TopBanner @discount-available="onDiscountAvailable"/>

  <!-- only here for the anchor -->
  <div id="home"></div>

  <Hero />

  <div class="relative overflow-hidden px-3 max-w-4xl mx-auto md:px-10">
    <AlexTestimonial />
  </div>

  <Why />

  <div class="relative overflow-hidden px-3 max-w-4xl mx-auto md:px-10">
    <Curriculum />
  </div>

  <!-- only here for the anchor -->
  <div id="testimonials" class="mb-20"></div>

  <TestimonialsCloud />

  <PreviewContents />

  <Pricing ref="pricingSection" />

  <HappinessGuarantee />

  <div class="relative overflow-hidden px-3 max-w-4xl mx-auto md:px-10">
    <!-- only here for the anchor -->
    <div id="about-me" class="mb-32"></div>

    <About />

    <FaqSection />

    <FooterComponent />
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner.vue'
import Hero from '@/components/HeroComponentV2.vue'
import Why from '@/components/WhyComponent.vue'
import Curriculum from '@/components/CurriculumComponent.vue'
import About from '@/components/AboutComponent.vue'
import PreviewContents from '@/components/PreviewContents.vue'
import Pricing from '@/components/PricingComponent.vue'
import HappinessGuarantee from '@/components/HappinessGuarantee.vue'
import FaqSection from '@/components/FaqSection.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import AlexTestimonial from '@/components/AlexTestimonial.vue'
import TestimonialsCloud from '@/components/TestimonialsCloud.vue'
import { getCookie, setCookie, getParameterByName } from '../libs/utils'
import { pageView } from '@/libs/analytics'

export default {
  name: 'HomeView',
  components: {
    TopBanner,
    Hero,
    Why,
    Curriculum,
    About,
    PreviewContents,
    Pricing,
    HappinessGuarantee,
    FaqSection,
    FooterComponent,
    AlexTestimonial,
    TestimonialsCloud
  },
  mounted () {
    this.navEvents = new Set()
    this.navSections = {}

    let affiliateId = getCookie('affiliateId')
    if (!affiliateId) {
      affiliateId = getParameterByName('affiliateid')
      if (affiliateId) {
        // Set affiliateId in cookie for 30 days if it's found in the query string parameter
        setCookie('affiliateId', affiliateId, 30)
      }
    }

    console.log('affiliateId:', affiliateId)
    pageView('Home', 'HomeView', affiliateId)
  },
  methods: {
    onDiscountAvailable (couponCode, discountPercentage) {
      this.$refs.pricingSection.handleDiscount(couponCode, discountPercentage)
    }
  }
}
</script>
