<template>
  <li class="flex items-center">
    <!-- Heroicon name: outline/check -->
    <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
    <p class="ml-3 text-left">{{ name }}</p>
  </li>
</template>

<script>
export default {
  props: ['name']
}
</script>
