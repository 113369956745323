<template>
  <div class="pt-40 md:mb-8 text-left background text-headline">
    <div class="pl-2 md:pl-4 lg:pl-0 grid grid-cols-1 md:grid-cols-2 max-w-5xl mx-auto">
      <!-- mobile -->
      <p class="hero-title md:hidden mb-4 text-3xl leading-tight">
        <span class="text-yellow-300">Master</span> Serverless Testing
      </p>
      <!-- tablet or above -->
      <p class="hero-title hidden md:block md:text-7xl lg:text-8xl leading-tight">
        <span class="text-yellow-300">Master</span>
        <br>
        Serverless
        <br>
        Testing
      </p>
      <div>
        <div class="relative">
          <img class="hidden md:block lg:hidden absolute left-[85px] top-[-10px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_50/testslsapps/handrawn-circle.png" />
          <img class="hidden lg:block absolute left-[112px] top-[-10px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_60/testslsapps/handrawn-circle.png" />
          <p class="text-lg md:text-2xl lg:text-3xl mb-8">Learn the <span class="font-extrabold text-yellow-300">smart way</span> to test serverless architectures.</p>
        </div>
        <p class="md:text-lg lg:text-xl mb-4">You'll learn…</p>
        <ul class="md:text-base lg:text-lg mb-12 md:pl-4 lg:pl-8">
          <CheckedListItem name="How to achieve a fast dev & test loop" />
          <CheckedListItem name="How to test API Gateway & AppSync APIs" />
          <CheckedListItem name="How to test Event-Driven Architectures" />
          <CheckedListItem name="How to test Step Function workflows" />
        </ul>
        <div class="flex flex-row">
          <div>
            <button class="p-3 px-6 md:px-8 lg:px-9 mr-7 md:mr-10 lg:mr-14 rounded-md text-button bg-[#7f5af0] hover:bg-[#6d42ee] md:text-lg lg:text-xl font-bold">
              <a href="#pricing">Let's GO!</a>
            </button>
          </div>
          <div class="flex flex-row items-center">
            <img class="rounded-full h-[60px] w-[60px] z-[2] overlap-image border-2 border-black hover:border-white" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_60/testslsapps/alex_debrie.png" alt="alex debrie" />
            <img class="rounded-full h-[60px] w-[60px] z-[1] overlap-image border-2 border-black hover:border-white" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_60/testslsapps/paul_swail.png" alt="paul swail" />
            <img class="rounded-full h-[60px] w-[60px] z-0 overlap-image border-2 border-black hover:border-white" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_60/testslsapps/roman-doric.jpg" alt="roman doric" />
            <span class="hidden lg:block ml-3 font-bold text-lg">500+ students</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-16 grid grid-cols-4 h-[30px]">
      <div class="col-span-1 bg-[#191E22]"></div>
      <div class="col-span-3 bg-white rounded-tl-full"></div>
    </div>
    <div class="grid grid-cols-4 h-[30px] bg-white">
      <div class="relative col-span-1 background rounded-br-full text-paragraph">
        <p class="hidden md:block absolute bottom-0 pl-3 lg:pl-0 lg:right-0 max-w-[250px] md:text-base lg:text-xl text-left font-medium pr-4 pb-2">
          Trusted by students from these companies
        </p>
        <p class="md:hidden absolute bottom-0 pl-3 lg:pl-0 lg:right-0 max-w-[250px] text-sm font-medium pr-2 pb-2">
          Trusted by students from ...
        </p>
      </div>
      <div class="relative col-span-3 bg-white">
        <div class="absolute top-0 md:top-[5px] pl-6 md:pl-12 flex flex-row gap-x-8 md:gap-x-16">
          <img class="h-[28px] md:h-[32px] lg:h-[40px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_40/testslsapps/logo-stedi.png" alt="stedi" />
          <img class="hidden md:block md:h-[32px] lg:h-[40px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_40/testslsapps/logo-veho.png" alt="veho" />
          <img class="h-[28px] md:h-[32px] lg:h-[40px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_40/testslsapps/logo-booking.png" alt="booking.com" />
          <img class="h-[28px] md:h-[32px] lg:h-[40px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_40/testslsapps/logo-yum.png" alt="yum" />
          <img class="hidden lg:block lg:h-[40px]" src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_40/testslsapps/logo-nn.png" alt="nn" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlap-image {
  @apply ml-[-12px];
}

.background {
  background-color: #16161a;
}

.text-button {
  color: #fffffe;
}

.text-headline {
  color: #fffffe;
}

.text-paragraph {
  color: #94a1b2;
}

</style>

<script>
import CheckedListItem from '@/components/hero/CheckedListItem.vue'

export default {
  name: 'HeroComponentV2',
  components: {
    CheckedListItem
  }
}
</script>
