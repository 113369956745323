<template>
  <div class="mt-20 text-left">
    <h2 class="text-3xl lg:text-4xl font-extrabold">Other courses</h2>

    <div class="mt-10">
      <p>If you want to learn more about serverless then check out my other courses or get the bundles for a great discount?</p>

      <ul class="grid grid-cols-1 lg:grid-cols-2 mt-10 gap-y-24 gap-x-10 max-w-6xl">
        <img src="https://res.cloudinary.com/theburningmonk/image/upload/v1674003419/testslsapps/other-course-complete-package.png" />
        <div class="flex flex-col">
          <p>Get all 3 of my other courses for the price of 2 with the <span class="font-bold">Complete Package</span>!</p>
          <p>This package contains:</p>
          <ul class="list-disc list-inside">
            <li>AppSync Masterclass premium package</li>
            <li>Learn you some Lambda best practice for great good!</li>
            <li>Complete guide to AWS Step Functions</li>
          </ul>

          <a class="rounded-lg bg-indigo-600 text-white w-fit mt-10 px-8 py-3 text-base font-medium"
            :href="`https://theburningmonk.thinkific.com/bundles/the-complete-package?source=${referer}`"
            target="_blank">Check out the bundle</a>
        </div>

        <img v-if="courses.includes('appsync')"
          src="https://res.cloudinary.com/theburningmonk/image/upload/testslsapps/other-course-appsync.png" />
        <div v-if="courses.includes('appsync')" class="flex flex-col">
          <p>Learn GraphQL and AppSync by building a Twitter clone from scratch, using AppSync, Lambda, DynamoDB, Cognito, Vue.js and Tailwind CSS.</p>
          <p>Along the way, you will learn how to design a GraphQL schema, how to unit test VTL templates and write tests for Lambda functions and so much more!</p>
          <p>You're gonna get your hands dirty and learn to build a full-stack application end-to-end and learn how to deal with CI/CD, caching, multi-tenancy and debugging problems once the application is running in the wild.</p>

          <a class="rounded-lg bg-indigo-600 text-white w-fit mt-5 px-8 py-3 text-base font-medium"
            href="https://appsyncmasterclass.com"
            target="_blank">Learn more</a>
        </div>

        <img v-if="courses.includes('llama')"
          src="https://res.cloudinary.com/theburningmonk/image/upload/v1674003419/testslsapps/other-course-llama.png" />
        <div v-if="courses.includes('llama')" class="flex flex-col">
          <p>Become an AWS Lambda blackbelt in bite-sized lessons.</p>
          <p>It's easy to get started with AWS Lambda and quickly ship something. But the thing is, there are lots of hidden decisions that can bite you in the ass later if you don't think about them.</p>

          <a class="rounded-lg bg-indigo-600 text-white w-fit mt-5 px-8 py-3 text-base font-medium"
            :href="`https://theburningmonk.thinkific.com/courses/learn-you-some-lambda?source=${referer}`"
            target="_blank">Check out the curriculum</a>
        </div>

        <img v-if="courses.includes('step-functions')"
          src="https://res.cloudinary.com/theburningmonk/image/upload/v1674003419/testslsapps/other-course-step-functions.png" />
        <div v-if="courses.includes('step-functions')" class="flex flex-col">
          <p>Learn how to build complex business workflows using AWS Step Functions.</p>
          <p>Learn the ins and outs of Step Functions. By the end of the course, you should have a good grasp of how it works, when to use it, and design patterns and best practices to help you get the most out of the Step Functions service.</p>

          <a class="rounded-lg bg-indigo-600 text-white w-fit mt-5 px-8 py-3 text-base font-medium"
            :href="`https://theburningmonk.thinkific.com/courses/complete-guide-to-aws-step-functions?source=${referer}`"
            target="_blank">Check out the curriculum</a>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['courses', 'referer']
}
</script>
