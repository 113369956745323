<template>
  <div id="curriculum" class="mt-24">
    <h1 class="text-4xl font-bold">Course Contents</h1>

    <Chapter v-for="chapter in chapters"
      v-bind:key="chapter.name"
      :chapterName="chapter.name"
      :summaries="chapter.summaries"
      :runtime="chapter.runtime"
      :hasCode="chapter.hasCode"
      :lessons="chapter.lessons" />
  </div>
</template>

<script>
import Chapter from './ChapterComponent.vue'

export default {
  components: {
    Chapter
  },
  data () {
    return {
      chapters: [
        {
          name: 'Introduction',
          runtime: '19m',
          hasCode: false,
          lessons: [
            {
              name: "What's so hard about testing serverless applications?",
              imageName: 'whats_so_hard_about_testing_serverless_apps.png'
            },
            {
              name: 'What to expect from this course',
              imageName: 'what_to_expect_from_this_course.png'
            },
            {
              name: 'What is included in this course',
              imageName: 'whats_included_in_this_course.png'
            }
          ]
        },
        {
          name: 'How to test serverless architectures',
          runtime: '1h42m',
          hasCode: true,
          summaries: [
            'General framework for testing serverless architectures.',
            'Understand what and how to test different parts of the system.',
            'Understand the challenges of testing serverless architectures.',
            'How to use remocal tests to achieve fast feedback loop.'
          ],
          lessons: [
            {
              name: 'Unit vs Integration vs E2E tests',
              imageName: 'unit_vs_integration_vs_e2e_tests.png'
            },
            {
              name: 'Hexagonal Architectures',
              imageName: 'hexagonal_architectures.png'
            },
            {
              name: 'Remocal tests: get the best of local & testing in the cloud',
              imageName: 'get_the_best_of_local_vs_testing_in_the_cloud.png'
            },
            {
              name: 'Troubleshooting failed E2E tests',
              imageName: 'troubleshooting_failed_e2e_tests.png'
            },
            {
              name: 'Bye bye Test Pyramid, hello Test Honeycomb',
              imageName: 'good_bye_test_pyramid_hello_test_honeycomb.png'
            },
            {
              name: 'Why you should use temporary environments',
              imageName: 'why_you_should_use_temporary_environments.png'
            }
          ]
        },
        {
          name: 'Testing API Gateway APIs',
          runtime: '1h10m',
          hasCode: true,
          summaries: [
            'General strategy for testing API Gateway APIs.',
            'How to test & debug Lambda functions locally.',
            'How to test API Gateway authorizers (Cognito).',
            'How to test API Gateway validators.',
            'How to test API Gateway service proxies.',
            'How to write reusable tests (so you need to maintain fewer tests).',
            'Demo project that you can try out for yourself.'
          ],
          lessons: [
            {
              name: 'Test strategy for API Gateway',
              imageName: 'test_strategy_for_api_gateway.png'
            },
            {
              name: 'Project overview',
              imageName: 'api_gateway_demo_overview.png'
            },
            {
              name: 'How to write integration tests',
              imageName: 'how_to_write_integration_tests_api_gateway.png'
            },
            {
              name: 'How to write end-to-end tests',
              imageName: 'how_to_write_e2e_tests_api_gateway.png'
            },
            {
              name: 'How to make tests more reusable',
              imageName: 'how_to_write_reusable_tests_api_gateway.png'
            }
          ]
        },
        {
          name: 'Testing AppSync APIs',
          runtime: '1h15m',
          hasCode: true,
          summaries: [
            'General strategy for testing AppSync APIs.',
            'How to test VTL templates locally.',
            'How to test & debug Lambda functions locally.',
            'How to test AppSync authorizers (Cognito).',
            'Demo project that you can try out for yourself.'
          ],
          lessons: [
            {
              name: 'Test strategy for AppSync',
              imageName: 'test_strategy_for_appsync.png'
            },
            {
              name: 'Project overview',
              imageName: 'appsync_demo_overview.png'
            },
            {
              name: 'How to test VTL templates with the AppSync SDK',
              imageName: 'appsync_test_vtl_evaluateMappingTemplate.png'
            },
            {
              name: "How to test VTL templates with Amplify's AppSync simulator",
              imageName: 'appsync_test_vtl_amplify.png'
            },
            {
              name: 'How to write integration tests',
              imageName: 'how_to_write_integration_tests_appsync.png'
            },
            {
              name: 'How to write end-to-end tests',
              imageName: 'how_to_write_e2e_tests_appsync.png'
            }
          ]
        },
        {
          name: 'Testing Step Functions',
          runtime: '2h08m',
          hasCode: true,
          summaries: [
            'Understand the unique challenges of testing Step Functions.',
            'General strategy for testing Step Functions.',
            'Understand when to use Step Functions Local vs. end-to-end tests.',
            'How to test with Step Functions Local.',
            'How to deal with timeout and wait states with long delay.',
            'How to improve end-to-end test coverage with mock APIs.',
            'Demo project that you can try out for yourself.'
          ],
          lessons: [
            {
              name: "What's so hard about testing Step Functions?",
              imageName: 'whats_so_hard_about_testing_step_functions.png'
            },
            {
              name: 'Orchestration vs Choreography',
              imageName: 'orchestration_vs_choreography.png'
            },
            {
              name: 'Test strategy for Step Functions',
              imageName: 'test_strategy_for_step_functions.png'
            },
            {
              name: 'How to test with Step Functions Local',
              imageName: 'how_to_test_with_step_functions_local.png'
            },
            {
              name: 'How to write end-to-end tests',
              imageName: 'how_to_write_e2e_tests_step_functions.png'
            },
            {
              name: 'Test timeout and wait states with Step Functions Local',
              imageName: 'test_timeout_and_wait_states_with_step_functions_local.png'
            },
            {
              name: 'How to use mock APIs to improve e2e test coverage',
              imageName: 'how_to_use_mock_apis_to_improve_e2e_test_coverage.png'
            }
          ]
        },
        {
          name: 'Testing Event-Driven Architectures (EDAs)',
          runtime: '2h',
          hasCode: true,
          summaries: [
            'Understand the unique challenges of testing event-driven architectures.',
            'General strategy for testing event-driven architectures.',
            'How to build an event sink to capture all events published by your system.',
            'How to listen to new events via websockets (no polling!).',
            'Demo project that you can try out for yourself.'
          ],
          lessons: [
            {
              name: 'What makes EDAs hard to test?',
              imageName: 'what_makes_edas_hard_to_test.png'
            },
            {
              name: 'Test strategy for EDAs',
              imageName: 'test_strategy_for_edas.png'
            },
            {
              name: 'How to capture published events',
              imageName: 'how_to_capture_published_events.png'
            },
            {
              name: 'Component testing for event publishers & consumers',
              imageName: 'component_testing_event_publishers_consumers.png'
            },
            {
              name: 'How to write end-to-end tests',
              imageName: 'end_to_end_for_edas.png'
            }
          ]
        },
        {
          name: 'Testing in Production',
          runtime: '1h',
          hasCode: false,
          summaries: [
            'Understand what we mean by "testing in production".',
            'Understand why you should test in production.',
            'Understand the risks of testing in production.',
            'Understand the different ways you can test in production (safely!).'
          ],
          lessons: [
            {
              name: 'What do we mean by "testing in production"?',
              imageName: 'what_do_we_mean_by_testing_in_production.png'
            },
            {
              name: 'Smoke tests',
              imageName: 'smoke_tests.png'
            },
            {
              name: 'Canary deployments',
              imageName: 'canary_deployment.png'
            },
            {
              name: 'Feature flagging',
              imageName: 'feature_flagging.png'
            },
            {
              name: 'Load testing',
              imageName: 'load_testing.png'
            },
            {
              name: 'Chaos engineering',
              imageName: 'chaos_engineering.png'
            },
            {
              name: 'Observability engineering',
              imageName: 'observability_engineering.png'
            }
          ]
        }]
    }
  }
}
</script>
