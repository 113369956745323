<template>
  <div id="preview" class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
    <h2 class="text-4xl font-bold">Preview Contents</h2>

    <div class="pt-10">
      <dl class="space-y-10 md:space-y-0 grid grid-cols-1 lg:grid-cols-2 gap-x-8 md:gap-y-12">
        <div class="relative">
          <video preload="none" controls class="w-full aspect-video border"
                  poster="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_333/testslsapps/whats_so_hard_about_testing_serverless_apps.png">
            <source src="https://d7kj8eb3qe4y5.cloudfront.net/tsa-whats-so-hard-about-testing-serverless.mp4" type="video/mp4">
            <track label="English" kind="subtitles" srclang="en" src="/subtitles/tsa-whats-so-hard-about-testing-serverless.vtt" default>
            Your browser does not support the video tag.
          </video>
          <p class="mt-3 font-extrabold">What's so hard about testing serverless?</p>
        </div>

        <div class="relative">
          <video preload="none" controls class="w-full aspect-video border"
                  poster="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_333/testslsapps/unit_vs_integration_vs_e2e_tests.png">
            <source src="https://d7kj8eb3qe4y5.cloudfront.net/tsa-unit-integration-e2e-tests.mp4" type="video/mp4">
            <track label="English" kind="subtitles" srclang="en" src="/subtitles/tsa-unit-integration-e2e-tests.vtt" default>
            Your browser does not support the video tag.
          </video>
          <p class="mt-3 font-extrabold">Unit vs Integration vs End-to-End tests</p>
        </div>

        <div class="relative">
          <video preload="none" controls class="w-full aspect-video border"
                  poster="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_333/testslsapps/hexagonal_architectures.png">
            <source src="https://d7kj8eb3qe4y5.cloudfront.net/tsa-hexagonal-architecture.mp4" type="video/mp4">
            <track label="English" kind="subtitles" srclang="en" src="/subtitles/tsa-hexagonal-architecture.vtt" default>
            Your browser does not support the video tag.
          </video>
          <p class="mt-3 font-extrabold">Hexagonal Architecture - the what, why and when</p>
        </div>

        <div class="relative">
          <video preload="none" controls class="w-full aspect-video border"
                  poster="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_333/testslsapps/test_strategy_for_api_gateway.png">
            <source src="https://d7kj8eb3qe4y5.cloudfront.net/tsa-the-strategy-for-testing-api-gateway.mp4" type="video/mp4">
            <track label="English" kind="subtitles" srclang="en" src="/subtitles/tsa-the-strategy-for-testing-api-gateway.vtt" default>
            Your browser does not support the video tag.
          </video>
          <p class="mt-3 font-extrabold">Strategy for testing API Gateway APIs</p>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
