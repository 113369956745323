<template>
  <div class="px-10 lg:px-32">
    <h1 class="pt-32 text-3xl lg:text-4xl font-extrabold">Testing Serverless Architectures (Basic)</h1>

    <div class="grid grid-cols-1 gap-y-10 lg:grid-cols-2 mt-14">
      <WhatsIncluded
        v-bind:listHeading="`This is what you will learn in the basic package:`"
        v-bind:contents="includedContents" />

      <CheckOutPaddle
        v-bind:productId="productId" />
    </div>

    <div class="mt-20 text-left">
      <h2 class="text-3xl lg:text-4xl font-extrabold">What's not included in the basic package</h2>

      <div class="mt-10">
        <p>If you're using Step Functions or you're building event-driven architectures then you should check out the <span class="text-xl font-bold">Pro package</span>.</p>
        <p>Which includes additional lessons such as:</p>

        <div v-for="content in proContents" v-bind:key="content.concept">
          <h3 class="text-lg font-bold my-2">{{ content.concept }}</h3>
          <ul class="list-disc list-inside">
            <li v-for="topic in content.topics" v-bind:key="topic">{{ topic }}</li>
          </ul>
        </div>
      </div>

      <div class="mt-10">
        <a href="#" class="rounded-lg bg-indigo-600 text-white px-8 py-3 text-base font-medium md:py-4 md:text-lg md:px-10"
          @click.prevent="goToHomePackages"> Compare the packages </a>
      </div>
    </div>

    <OtherCourses
      v-bind:courses="otherCourses"
      v-bind:referer="`TestingServerlessArchsBasic-Checkout`" />

    <FooterComponent />
  </div>
</template>

<script>
import WhatsIncluded from '../components/CheckOutWhatsIncluded.vue'
import CheckOutPaddle from '../components/CheckOutPaddle.vue'
import OtherCourses from '../components/CheckOutOtherCourses.vue'
import FooterComponent from '../components/FooterComponent.vue'
import { ProductIds } from '../libs/constants'

export default {
  components: {
    WhatsIncluded,
    CheckOutPaddle,
    OtherCourses,
    FooterComponent
  },
  data () {
    return {
      productId: ProductIds.TestingServerlessArchsBasic,
      includedContents: [
        {
          concept: 'How to test serverless architectures',
          topics: [
            "What's so hard about testing serverless anyway?",
            'How to write unit, integration and end-to-end tests for serverless architectures.',
            'When to use local tests vs testing in the cloud?',
            'How to get the best of local testing and testing in the cloud.',
            'How to debug failed end-to-end tests.',
            'Why you should use temporary environments.',
            'What are hexagonal architectures and how they make your code easier to test.',
            'A general strategy for testing serverless architectures.'
          ]
        },
        {
          concept: 'Testing API Gateway APIs',
          topics: [
            'What are the unique challenges with testing API Gateway APIs?',
            'A stratey for testing API Gateway APIs.',
            'How to test Cognito authorizers and direct service integrations?',
            'How to write integration and end-to-end tests?',
            'An advanced technique for reducing the number of tests you have to write and maintain.'
          ]
        },
        {
          concept: 'Testing AppSync APIs',
          topics: [
            'What are the unique challenges with testing AppSync APIs?',
            'A stratey for testing AppSync APIs.',
            'How to test VTL templates?',
            'How to test direct service integrations as well as Lambda resolvers?',
            'How to write integration and end-to-end tests?'
          ]
        }
      ],
      proContents: [
        {
          concept: 'Testing Step Functions',
          topics: [
            'What are the unique challenges with testing Step Functions?',
            'A stratey for testing Step Functions.',
            'How and when to use Step Functions Local?',
            'How to write end-to-end tests?',
            'How to handle long timeouts and wait states?',
            'How to use mock APIs to improve coverage of end-to-end tests?'
          ]
        },
        {
          concept: 'Testing Event-Driven Architectures',
          topics: [
            'What are the unique challenges with testing event-driven architectures?',
            'A stratey for testing event-driven architectures.',
            'How to capture the published events so we can validate them?',
            'How to write component tests for the individual event publishers and subscribers?',
            'How to write end-to-end tests?'
          ]
        },
        {
          concept: 'Testing in production',
          topics: [
            'What do we mean by "testing in production"?',
            'How can we test in production safely?',
            'What are the considerations for canary deployments and feature flagging?',
            'How should we approach load testing for serverless architectures?',
            'How can we apply chaos engineering to serverless?',
            'How can we improve observability of serverless architectures?'
          ]
        }
      ],
      otherCourses: [
        'llama',
        'step-functions',
        'appsync'
      ]
    }
  },
  mounted () {
    document.title = 'Check out: Testing Serverless Architectures (basic)'
  },
  methods: {
    goToHomePackages () {
      this.$router.push('/#pricing')
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 1em;
}
</style>
