<template>
  <div id="alex-testimonial" class="mt-16 text-left">
    <div>
      <svg class="h-7 m-1 fill-slate-400 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M96 224C84.72 224 74.05 226.3 64 229.9V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32S145.7 96 128 96C57.42 96 0 153.4 0 224v96c0 53.02 42.98 96 96 96s96-42.98 96-96S149 224 96 224zM352 224c-11.28 0-21.95 2.305-32 5.879V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32s-14.33-32-32-32c-70.58 0-128 57.42-128 128v96c0 53.02 42.98 96 96 96s96-42.98 96-96S405 224 352 224z"/>
      </svg>
      <p class="text-xl md:text-2xl font-bold tracking-tight">
        Yan is the expert I trust the most on building serverless architectures, and
        <span class="px-2 py-1 font-extrabold text-black bg-yellow-100">
        this is the course if you want to learn how to test well with serverless.
        </span>
        <br>
        This course will help you move faster, deploy safely, and enjoy developing
        serverless applications.
      </p>
    </div>
    <div class="flex items-center mt-4">
      <img loading="lazy" class="h-16 md:h-24 w-16 md:w-24 rounded-full m-2"
        srcset="
          https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_64/testslsapps/alex_debrie.png 640w,
          https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_96/testslsapps/alex_debrie.png 768w"
        src="https://res.cloudinary.com/theburningmonk/image/upload/f_auto,c_scale,h_64/testslsapps/alex_debrie.png">
      <div class="flex flex-col justify-start">
        <p class="text-lg text-left md:text-xl font-bold">Alex DeBrie</p>
        <p class="text-md text-left md:text-lg font-thin">AWS Data Hero & Author of "The DynamoDB Book"</p>
      </div>
    </div>
  </div>
</template>
