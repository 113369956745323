<template>
  <div class="pr-10 text-left">
    <p>{{ tagline || 'Thank you for checking out Testing Serverless Architectures!' }}</p>
    <p>{{ listHeading }}</p>

    <div v-for="content in contents" v-bind:key="content.concept">
      <h3 class="text-lg font-bold my-2">{{ content.concept }}</h3>
      <ul class="list-disc list-inside">
        <li v-for="topic in content.topics" v-bind:key="topic">{{ topic }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tagline', 'listHeading', 'contents', 'includeSneakPeek']
}
</script>

<style scoped>
p {
  margin-bottom: 1em;
}
</style>
