<template>
  <div class="mt-10 w-full">
    <h1 class="text-left text-2xl font-bold mb-4">{{ chapterName }}</h1>

    <div class="flex flex-col sm:flex-row gap-x-5 mb-4">
      <div v-if="hasCode" class="flex flex-row">
        <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/>
        </svg>
        <span>code example</span>
      </div>
      <div class="flex flex-row">
        <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"/>
        </svg>
        <span>{{ runtime }} of learning material</span>
      </div>
    </div>

    <ul v-if="summaries" class="md:pl-5 text-left mb-4 text-lg list-inside">
      <li v-for="paragraph in summaries" v-bind:key="paragraph">
        <div class="flex flex-row gap-x-3 items-center">
          <div>
            <!-- Heroicon name: outline/check -->
            <svg class="h-5 w-5 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </div>
          <p>{{ paragraph }}</p>
        </div>
      </li>
    </ul>

    <p class="mt-5 text-lg font-semibold text-left">Featured Lessons:</p>
    <div class="grid grid-cols-2 md:grid-cols-3 justify-items-start gap-4">
      <Lesson v-for="lesson in lessons"
        v-bind:key="lesson.name"
        :name="lesson.name"
        :imageName="lesson.imageName"/>
    </div>
  </div>
</template>

<script>
import Lesson from './LessonComponent.vue'

export default {
  props: ['chapterName', 'summaries', 'lessons', 'runtime', 'hasCode'],
  components: {
    Lesson
  }
}
</script>
